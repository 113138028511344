const emailInput = document.getElementById('emailInput')
const sendButton = document.getElementById('sendButton')
const inputBlock = document.getElementById('inputBlock')
const closeModal = document.getElementById('closeModal')
const modalBg = document.querySelector('.modal-bg')
const modal = document.querySelector('.modal')

let onTouch = false
let sendMailUrl = 'https://legalai.kz/api/auth/notice/saveEmail'

if (emailInput.value.trim().length === 0) {
  sendButton.setAttribute('disabled', 'disabled');
}

emailInput.addEventListener('blur', () => {
  onTouch = true
  validateEmail()
})

emailInput.addEventListener('input', () => {
  validateEmail()
})


function validateEmail() {
  const regExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (emailInput.value.trim().length !== 0 && regExp.test(emailInput.value)) {
    sendButton.removeAttribute('disabled');
    inputBlock.classList.remove('error')
  } else {
    sendButton.setAttribute('disabled', 'disabled');
    if (onTouch) {
      inputBlock.classList.add('error')
    }
  }
}

closeModal.addEventListener('click', () => {
  modalBg.classList.remove('active')
  modal.classList.remove('active')
})

sendButton.addEventListener('click', () => {
  sendMailUrl = `${sendMailUrl}/${emailInput.value}`
  fetch(sendMailUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })
    .then(response => response.json())
    .then((res) => {
      modalBg.classList.add('active')
      modal.classList.add('active')
      emailInput.value = ''
    })
    .catch((error) => {
    })
})